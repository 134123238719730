import React, { Component } from "react";
//import logo from "./logo.svg";
import "./App.css";

class PricingEstimator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactsCount: 0,
      emailsCount: 0,
      activePrice: 0,
      isLoading: false,
    };
  }

  componentDidMount() {
    let contacts = 0;
    let emails = 0;
    this.calculatePrice(contacts, emails);
  }

  handleContactChange(val) {
    // console.log(`contact: ${Number(val.target.value)}`);
    this.setState({
      isLoading: true,
      contactsCount: val.target.value,
      // activePrice: this.calculatePrice(Number(val.target.value), null),
    });
    this.calculatePrice(Number(val.target.value), this.state.emailsCount);
  }

  handleEmailChange(val) {
    // console.log(`email: ${Number(val.target.value)}`);
    this.setState({
      isLoading: true,
      emailsCount: val.target.value,
      // activePrice: this.calculatePrice(null, Number(val.target.value)),
    });
    this.calculatePrice(this.state.contactsCount, Number(val.target.value));
  }

  calculatePrice(contacts, emails) {
    let price = 0;
    if (contacts || emails) {
      if (contacts <= 5000 || emails <= 60000) {
        price = "149";
      } 
      if (
        (contacts >= 5001 && contacts <= 10000) ||
        (emails >= 60001 && emails <= 120000)
      ) {
        price = "299";
      }
      if (
        (contacts >= 10001 && contacts <= 15000) ||
        (emails >= 120001 && emails <= 180000)
      ) {
        price = "349";
      }  if (
        (contacts >= 15001 && contacts <= 20000) ||
        (emails >= 180001 && emails <= 240000)
      ) {
        price = "399";
      }  if (
        (contacts >= 20001 && contacts <= 25000) ||
        (emails >= 240001 && emails <= 300000)
      ) {
        price = "449";
      }  if (
        (contacts >= 25001 && contacts <= 30000) ||
        (emails >= 300001 && emails <= 360000)
      ) {
        price = "499";
      }  if (
        (contacts >= 30001 && contacts <= 40000) ||
        (emails >= 360001 && emails <= 480000)
      ) {
        price = "549";
      }  if (
        (contacts >= 40001 && contacts <= 50000) ||
        (emails >= 480001 && emails <= 600000)
      ) {
        price = "599";
      }  if (
        (contacts >= 50001 && contacts <= 60000) ||
        (emails >= 600001 && emails <= 720000)
      ) {
        price = "649";
      }  if (
        (contacts >= 60001 && contacts <= 100000) ||
        (emails >= 720001 && emails <= 1200000)
      ) {
        price = "699";
      }  if (
        (contacts >= 100001 && contacts <= 130000) ||
        (emails >= 1200001 && emails <= 1560000)
      ) {
        price = "799";
      }  if (
        (contacts >= 130001 && contacts <= 150000) ||
        (emails >= 1560001 && emails <= 1800000)
      ) {
        price = "899";
      }  if (
        (contacts >= 150001 && contacts <= 200000) ||
        (emails >= 1800001 && emails <= 2400000)
      ) {
        price = "1099";
      }  if (contacts >= 200001 || emails >= 2400000) {
        price = "Contact Sales";
      }
    }
    // console.log(`activePrice: ${price}`);
    this.setState({activePrice: price, isLoading: false});
    return;
  }

  render() {
    return (
      <div className="App">
        <div className="box">
          <div className="left-column">
            <div className="form-group">
              <label className="field-label large">
                How many contacts do you have?
              </label>
              <input
                name="contacts"
                className="text-field large quantity"
                type="number"
                value={(this.state.contactsCount).toLocaleString('en-US',{useGrouping:true})}
                onChange={(val) => this.handleContactChange(val)}
              />
              <label className="field-label large">
                How many emails per month do you have?
              </label>
              <input
                name="emails"
                className="text-field large quantity"
                type="number"
                value={(this.state.emailsCount).toLocaleString('en-US',{useGrouping:true})}
                onChange={(val) => this.handleEmailChange(val)}
              />
            </div>
            <div className="total-wrapper">
              {(this.state.activePrice && this.state.activePrice !== "Contact Sales") ? <div className="total-text-outer-wrapper">
                <span className="uppercase-title no-top-margin small">
                  Your Total
                </span>
                <div className="total-text-wrapper">
    <span className="total">${this.state.activePrice}<span className="total-time">/mo</span></span>
                  
                </div>
              </div>: <a
                href="https://www.mirabelsmarketingmanager.com/contact-us"
                className="cta-link-blue no-top-margin"
                id="contact-sales-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact Sales
              </a>}
              
              
            </div>
          </div>
          <div className="right-column">
            <span className="h5">Pay As You Go Rates</span>
            <table className="volume-rate-table">
              <thead>
                <tr>
                  <th>Contacts</th>
                  <th>Emails</th>
                  <th>Users</th>
                  <th>Price per month</th>
                </tr>
              </thead>
              <tbody>
              <tr id="149" className={this.state.activePrice =="149" ? "active":"inactive"}>
                  <td>
                    5,000
                  </td>
                  <td>
                    60,000
                  </td>
                  <td>5</td>
                  <td>$149*</td>
                </tr>
                <tr id="299" className={this.state.activePrice =="299" ? "active":"inactive"}>
                  <td>
                    10,000
                  </td>
                  <td>
                    120,000
                  </td>
                  <td>5</td>
                  <td>$299*</td>
                </tr>
                <tr id="349" className={this.state.activePrice =="349" ? "active":"inactive"}>
                  <td>
                    15,000
                  </td>
                  <td>
                    180,000
                  </td>
                  <td>5</td>
                  <td>$349*</td>
                </tr>
                <tr id="399" className={this.state.activePrice =="399" ? "active":"inactive"}>
                  <td>
                    20,000
                  </td>
                  <td>
                    240,000
                  </td>
                  <td>5</td>
                  <td>$399*</td>
                </tr>
                <tr id="449" className={this.state.activePrice =="449" ? "active":"inactive"}>
                  <td>
                    25,000
                  </td>
                  <td>
                    300,000
                  </td>
                  <td>5</td>
                  <td>$449*</td>
                </tr>
                <tr id="499" className={this.state.activePrice =="499" ? "active":"inactive"}>
                  <td>
                    30,000
                  </td>
                  <td>
                    360,000
                  </td>
                  <td>5</td>
                  <td>$499*</td>
                </tr>
                <tr id="549" className={this.state.activePrice =="549" ? "active":"inactive"}>
                  <td>
                    40,000
                  </td>
                  <td>
                    480,000
                  </td>
                  <td>5</td>
                  <td>$549*</td>
                </tr>
                <tr id="599" className={this.state.activePrice =="599" ? "active":"inactive"}>
                  <td>
                    50,000
                  </td>
                  <td>
                    600,000
                  </td>
                  <td>5</td>
                  <td>$599*</td>
                </tr>
                <tr id="649" className={this.state.activePrice =="649" ? "active":"inactive"}>
                  <td>
                    60,000
                  </td>
                  <td>
                    720,000
                  </td>
                  <td>5</td>
                  <td>$649*</td>
                </tr>
                <tr id="699" className={this.state.activePrice =="699" ? "active":"inactive"}>
                  <td>
                    100,000
                  </td>
                  <td>
                    1,200,000
                  </td>
                  <td>5</td>
                  <td>$699*</td>
                </tr>
                <tr id="799" className={this.state.activePrice =="799" ? "active":"inactive"}>
                  <td>
                    130,000
                  </td>
                  <td>
                    1,560,000
                  </td>
                  <td>5</td>
                  <td>$799*</td>
                </tr>
                <tr id="899" className={this.state.activePrice =="899" ? "active":"inactive"}>
                  <td>
                    150,000
                  </td>
                  <td>
                    1,800,000
                  </td>
                  <td>5</td>
                  <td>$899*</td>
                </tr>
                <tr id="1099" className={this.state.activePrice =="1099" ? "active":"inactive"}>
                  <td>
                    200,000
                  </td>
                  <td>
                    2,400,000
                  </td>
                  <td>5</td>
                  <td>$1099*</td>
                </tr>
                <tr id="Contact Sales" className={this.state.activePrice =="Contact Sales" ? "active":"inactive"}>
                  <td>
                    >200,000
                  </td>
                  <td>
                    
                  </td>
                  <td></td>
                  <td>Contact Sales</td>
                </tr>
              </tbody>
            </table>
            <span className="h5">*Price for 1 website; additional websites $99 each per month</span>
          </div>
        </div>
      </div>
    );
  }
}

export default PricingEstimator;
